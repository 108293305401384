import { useState, useEffect } from 'react';
import Spacing from '../Spacing';
import Lottie from 'react-lottie';
import loading from '../../loading.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export default function StepNominee({
    loading,
    error,
    message,
    familyDetails,
    setFamilyDetails,
    saveNominees,
    goToPreFive
}) {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleFamilyMemberChange = (key, e) => {
        const { name, value } = e.target;
        setFamilyDetails({
            ...familyDetails,
            [key]: {
                ...familyDetails[key],
                [name]: value,
            },
        });
    };

    return (
        <>
            <h2 className="cs_list_title cs_medium cs_fs_24" style={{ paddingLeft: '0px' }}>
                Nominees
            </h2>
            <div className="row">
                <div className="col-lg-6">
                    <h6>Nominee 1</h6>
                </div>
                {!isMobile &&
                    <div className="col-lg-6">
                        <h6>Nominee 2 (Optional)</h6>
                    </div>
                }
                <div className="col-lg-3">
                    <label className="cs_input_label cs_heading_color">
                        Full Name
                    </label>
                    <input
                        type="text"
                        name="name"
                        className="cs_form_field"
                        placeholder="Enter Full Name"
                        value={familyDetails["nomineeOne"]["name"]}
                        onChange={(a) => handleFamilyMemberChange("nomineeOne", a)}
                    />
                    <div className="cs_height_42 cs_height_xl_25" />
                </div>
                <div className="col-lg-3">
                    <label className="cs_input_label cs_heading_color">Relationship</label>
                    <div className="cs_with_icon_input">
                        <select
                            value={familyDetails["nomineeOne"]["relationship"]}
                            onChange={(a) => handleFamilyMemberChange("nomineeOne", a)}
                            name="relationship"
                            style={{
                                border: '1px solid rgba(210, 83, 140, 0.5)',
                                borderRadius: '10px',
                                padding: '16px 20px',
                                marginRight: '10px'
                            }}>
                            <option value=''>
                                Choose Relationship
                            </option>
                            <option value="Spouse">Spouse</option>
                            <option value="Child">Child</option>
                            <option value="Mother">Mother</option>
                            <option value="Father">Father</option>
                            <option value="Mother-in-law">Mother in law</option>
                            <option value="Father-in-law">Father in law</option>
                            <option value="Brother">Brother</option>
                            <option value="Sister">Sister</option>
                        </select>
                    </div>
                    <div className="cs_height_42 cs_height_xl_25" />
                </div>
                {isMobile &&
                    <div className="col-lg-6">
                        <h6>Nominee 2 (Optional)</h6>
                    </div>
                }
                <div className="col-lg-3">
                    <label className="cs_input_label cs_heading_color">
                        Full Name
                    </label>
                    <input
                        type="text"
                        name="name"
                        className="cs_form_field"
                        placeholder="Enter Full Name"
                        value={familyDetails["nomineeTwo"]["name"]}
                        onChange={(a) => handleFamilyMemberChange("nomineeTwo", a)}
                    />
                    <div className="cs_height_42 cs_height_xl_25" />
                </div>
                <div className="col-lg-3">
                    <label className="cs_input_label cs_heading_color">Relationship</label>
                    <div className="cs_with_icon_input">
                        <select
                            value={familyDetails["nomineeTwo"]["relationship"]}
                            onChange={(a) => handleFamilyMemberChange("nomineeTwo", a)}
                            name="relationship"
                            style={{
                                border: '1px solid rgba(210, 83, 140, 0.5)',
                                borderRadius: '10px',
                                padding: '16px 20px',
                                marginRight: '10px'
                            }}>
                            <option value=''>
                                Choose Relationship
                            </option>
                            <option value="Spouse">Spouse</option>
                            <option value="Child">Child</option>
                            <option value="Mother">Mother</option>
                            <option value="Father">Father</option>
                            <option value="Mother-in-law">Mother in law</option>
                            <option value="Father-in-law">Father in law</option>
                            <option value="Brother">Brother</option>
                            <option value="Sister">Sister</option>
                        </select>
                    </div>
                    <div className="cs_height_42 cs_height_xl_25" />
                </div>
                <div className="col-lg-6">
                    <h6>Nominee 3 (Optional)</h6>
                </div>
                {!isMobile &&
                    <div className="col-lg-6">
                        <h6>Nominee 4 (Optional)</h6>
                    </div>
                }
                <div className="col-lg-3">
                    <label className="cs_input_label cs_heading_color">
                        Full Name
                    </label>
                    <input
                        type="text"
                        name="name"
                        className="cs_form_field"
                        placeholder="Enter Full Name"
                        value={familyDetails["nomineeThree"]["name"]}
                        onChange={(a) => handleFamilyMemberChange("nomineeThree", a)}
                    />
                    <div className="cs_height_42 cs_height_xl_25" />
                </div>
                <div className="col-lg-3">
                    <label className="cs_input_label cs_heading_color">Relationship</label>
                    <div className="cs_with_icon_input">
                        <select
                            value={familyDetails["nomineeThree"]["relationship"]}
                            onChange={(a) => handleFamilyMemberChange("nomineeThree", a)}
                            name="relationship"
                            style={{
                                border: '1px solid rgba(210, 83, 140, 0.5)',
                                borderRadius: '10px',
                                padding: '16px 20px',
                                marginRight: '10px'
                            }}>
                            <option value=''>
                                Choose Relationship
                            </option>
                            <option value="Spouse">Spouse</option>
                            <option value="Child">Child</option>
                            <option value="Mother">Mother</option>
                            <option value="Father">Father</option>
                            <option value="Mother-in-law">Mother in law</option>
                            <option value="Father-in-law">Father in law</option>
                            <option value="Brother">Brother</option>
                            <option value="Sister">Sister</option>
                        </select>
                    </div>
                    <div className="cs_height_42 cs_height_xl_25" />
                </div>
                {isMobile &&
                    <div className="col-lg-6">
                        <h6>Nominee 4 (Optional)</h6>
                    </div>
                }
                <div className="col-lg-3">
                    <label className="cs_input_label cs_heading_color">
                        Full Name
                    </label>
                    <input
                        type="text"
                        name="name"
                        className="cs_form_field"
                        placeholder="Enter Full Name"
                        value={familyDetails["nomineeFour"]["name"]}
                        onChange={(a) => handleFamilyMemberChange("nomineeFour", a)}
                    />
                    <div className="cs_height_42 cs_height_xl_25" />
                </div>
                <div className="col-lg-3">
                    <label className="cs_input_label cs_heading_color">Relationship</label>
                    <div className="cs_with_icon_input">
                        <select
                            value={familyDetails["nomineeFour"]["relationship"]}
                            onChange={(a) => handleFamilyMemberChange("nomineeFour", a)}
                            name="relationship"
                            style={{
                                border: '1px solid rgba(210, 83, 140, 0.5)',
                                borderRadius: '10px',
                                padding: '16px 20px',
                                marginRight: '10px'
                            }}>
                            <option value=''>
                                Choose Relationship
                            </option>
                            <option value="Spouse">Spouse</option>
                            <option value="Child">Child</option>
                            <option value="Mother">Mother</option>
                            <option value="Father">Father</option>
                            <option value="Mother-in-law">Mother in law</option>
                            <option value="Father-in-law">Father in law</option>
                            <option value="Brother">Brother</option>
                            <option value="Sister">Sister</option>
                        </select>
                    </div>
                    <div className="cs_height_42 cs_height_xl_25" />
                </div>
            </div>
            <Spacing md="20" />
            {loading ? (
                <button className="cs_btn cs_style_1">
                    <span>Continue</span>
                    <Lottie options={defaultOptions} width={45} />
                </button>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <button className="cs_btn cs_style_1" onClick={goToPreFive} style={{ marginRight: '10px' }}>
                        <span>Previous</span>
                    </button>
                    <button className="cs_btn cs_style_1" onClick={saveNominees}>
                        <span>Continue</span>
                        <i>
                            <img src={'/images/icons/arrow_white.svg'} alt="Icon" />
                            <img src={'/images/icons/arrow_white.svg'} alt="Icon" />
                        </i>
                    </button>
                </div>
            )}
            {error &&
                <div style={{ color: 'red', fontSize: '12px' }}>
                    {message}
                </div>
            }
        </>
    );
}