import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { pageTitle } from "../../helpers/PageTitle";
import Spacing from "../Spacing";
import AboutSection from "../Section/AboutSection";
import Lottie from "react-lottie";
import loading from "../../loading.json";
import {
  getDistricts,
  getPlaces,
  savePartner,
} from "../../redux/user/userSlice";
import moment from "moment";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const today = new Date().toLocaleDateString();

export default function Partner() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState(false);
  const [failed, setFailed] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [places, setPlaces] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [alert, setAlert] = useState(false);

  const currentYear = new Date().getFullYear();
  const minYear = currentYear - 71;
  const maxYear = currentYear - 18;

  const days = Array.from({ length: 31 }, (_, i) => String(i + 1).padStart(2, '0'));
  const months = Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0'));
  const years = Array.from({ length: maxYear - minYear + 1 }, (_, i) => minYear + i);

  const [formData, setFormData] = useState({
    name: "",
    day: "",
    month: "",
    year: "",
    phone: "",
    email: "",
    gender: "",
    address: "",
    bank: "",
    account: "",
    ifsc: "",
    gst: "",
    entity: "",
    district: "",
    type: "",
    place: "",
    pan: "",
    adhaar: "",
    isMsme: null,
    msmeType: "",
    msmeNumber: ""
  });

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    dispatch(getDistricts()).then((response) => {
      if (response.payload && response.payload?.success) {
        setDistricts(response.payload?.districts);
      }
    });
  }, []);

  const _getPlaces = (field, value) => {
    handleChange(field, value);

    if (field == "district") {
      if (formData.type) {
        dispatch(getPlaces({ district_id: value, type: formData.type })).then(
          (response) => {
            if (response.payload && response.payload?.success) {
              setPlaces(response.payload?.places);
            }
          }
        );
      }
    } else {
      if (formData.district) {
        dispatch(
          getPlaces({ district_id: formData.district, type: value })
        ).then((response) => {
          if (response.payload && response.payload?.success) {
            setPlaces(response.payload?.places);
          }
        });
      }
    }
  };

  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const validateForm = () => {
    const requiredFields = [
      "name",
      "day",
      "month",
      "year",
      "phone",
      "gender",
      "address",
      "bank",
      "account",
      "ifsc",
      "entity",
      "district",
      "type",
      "place",
      "pan",
      "adhaar"
    ];
    const allFilled = requiredFields.every((field) => {
      const value = formData[field];
      return typeof value === "string"
        ? value.trim() !== ""
        : value !== null && value !== "";
    });
    return allFilled;
  };

  const validateDOB = () => {
    const { day, month, year } = formData;

    if (!day || !month || !year) {setFailed(true);
      setMessage('Please enter a valid date of birth');
      setTimeout(() => {
        setFailed(false);
        setMessage(null);
      }, 5000);
      return;
    }

    const dob = new Date(year, month - 1, day); // JavaScript months are 0-indexed
    const today = new Date();

    const ageInMilliseconds = today - dob;
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Convert to years

    if (ageInYears < 18) {
      setFailed(true);
      setMessage('Age must be at least 18 years');
      setTimeout(() => {
        setFailed(false);
        setMessage(null);
      }, 5000);
      return;
    } else if (ageInYears > 70) {
      setFailed(true);
      setMessage('Age must be less than 70 years');
      setTimeout(() => {
        setFailed(false);
        setMessage(null);
      }, 5000);
      return;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!isChecked) {
      // If isChecked is not true, show alert and setHide to true
      setAlert(true);
      return; // Exit the function early
    }

    if (formData.phone.length != 10) {
      setFailed(true);
      setMessage('Enter valid phone number with 10 digits');
      setTimeout(() => {
        setFailed(false);
        setMessage(null);
      }, 5000);
      return; // Exit the function early
    }

    if (!validateDOB()) {
      return; // Exit the function early
    }

    const aadhaarRegex = /^\d+$/;
    if (formData.adhaar && !aadhaarRegex.test(formData.adhaar)) {
      setFailed(true);
      setMessage('Aadhaar number must contain only numbers');
      setTimeout(() => {
        setFailed(false);
        setMessage(null);
      }, 5000);
      return;
    }

    if (validateForm()) {
      setLoading(true);

      dispatch(savePartner(formData)).then((response) => {
        if (response?.payload?.success) {
          setSuccess(true);
          setTimeout(() => {
            window.location.href =
              "https://pricing.getafixmd.com/public/sales-login"; // Redirect to the new URL
          }, 2000);
        } else {
          setFailed(true);
          setMessage(response?.payload?.message);
          setLoading(false);
        }
      });
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
    }
  };

  pageTitle("Partner");

  return (
    <>
      <Spacing md="170" />
      <AboutSection
        imgUrl="/images/home_1/about.png"
        // spiningImgUrl="/images/home_1/about_mini.svg"
        title="Become"
        subTitle="A Partner"
        featureList={[
          {
            featureListTitle:
              "Partner with Us as a Channel Partner at GetAFixMD",
            featureListSubTitle:
              "Join GetAFixMD as a channel partner to expand your offerings with our leading healthcare solutions and drive mutual growth.",
          },
        ]}
      />
      <Spacing md="100" />
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">Full Name</label>
            <input
              type="text"
              className="cs_form_field"
              placeholder="Enter your Full Name"
              value={formData.name}
              onChange={(a) => handleChange("name", a.target.value)}
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">
              Date of Birth
            </label>
            <div className="cs_with_icon_input">
              <select value={formData.day} onChange={(a) => handleChange("day", a.target.value)}
                style={{
                  border: '1px solid rgba(210, 83, 140, 0.5)',
                  borderRadius: '10px',
                  padding: '16px 20px',
                  marginRight: '10px'
                }}>
                <option value="">Day</option>
                {days.map((d) => (
                  <option key={d} value={d}>
                    {d}
                  </option>
                ))}
              </select>

              <select value={formData.month} onChange={(a) => handleChange("month", a.target.value)}
                style={{
                  border: '1px solid rgba(210, 83, 140, 0.5)',
                  borderRadius: '10px',
                  padding: '16px 20px',
                  marginRight: '10px'
                }}>
                <option value="">Month</option>
                {months.map((m) => (
                  <option key={m} value={m}>
                    {m}
                  </option>
                ))}
              </select>

              <select value={formData.year} onChange={(a) => handleChange("year", a.target.value)}
                style={{
                  border: '1px solid rgba(210, 83, 140, 0.5)',
                  borderRadius: '10px',
                  padding: '16px 20px'
                }}>
                <option value="">Year</option>
                {years.map((y) => (
                  <option key={y} value={y}>
                    {y}
                  </option>
                ))}
              </select>
            </div>
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">Phone</label>
            <input
              type="tel"
              className="cs_form_field"
              placeholder="Enter your Phone"
              value={formData.phone}
              onChange={(a) => handleChange("phone", a.target.value)}
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">Email (Optional)</label>
            <input
              type="email"
              className="cs_form_field"
              placeholder="Enter your Email"
              value={formData.email}
              onChange={(a) => handleChange("email", a.target.value)}
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">Gender</label>
            <div className="cs_radio_group">
              <div className="cs_radio_wrap">
                <input
                  className="cs_radio_input"
                  type="radio"
                  name="gender"
                  id="male"
                  value={"MALE"}
                  onChange={(a) => handleChange("gender", a.target.value)}
                />
                <label className="cs_radio_label" htmlFor="male">
                  Male
                </label>
              </div>
              <div className="cs_radio_wrap">
                <input
                  className="cs_radio_input"
                  type="radio"
                  name="gender"
                  id="female"
                  value={"FEMALE"}
                  onChange={(a) => handleChange("gender", a.target.value)}
                />
                <label className="cs_radio_label" htmlFor="female">
                  Female
                </label>
              </div>
            </div>
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">Address</label>
            <textarea
              type="text"
              className="cs_form_field"
              placeholder="Enter your address"
              onChange={(a) => handleChange("address", a.target.value)}
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">Bank Name</label>
            <input
              type="text"
              className="cs_form_field"
              placeholder="Enter your Bank Name"
              onChange={(a) => handleChange("bank", a.target.value)}
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">
              Account Number
            </label>
            <input
              type="text"
              className="cs_form_field"
              placeholder="Enter your Bank account number"
              onChange={(a) => handleChange("account", a.target.value)}
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">IFS Code</label>
            <input
              type="text"
              className="cs_form_field"
              placeholder="Enter the IFS Code"
              onChange={(a) => handleChange("ifsc", a.target.value)}
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>

          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">
              PAN Number
            </label>
            <input
              type="text"
              className="cs_form_field"
              placeholder="Enter your Pan number"
              onChange={(a) => handleChange("pan", a.target.value)}
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">
              Adhaar Number
            </label>
            <input
              type="text"
              className="cs_form_field"
              placeholder="Enter your Adhaar number"
              onChange={(a) => handleChange("adhaar", a.target.value)}
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">
              GSTIN (Optional)
            </label>
            <input
              className="cs_form_field"
              placeholder="Enter your GSTIN number"
              onChange={(a) => handleChange("gst", a.target.value)}
            />
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">
              Business Entity Type
            </label>
            <select
              onChange={(a) => handleChange("entity", a.target.value)}
              className="cs_form_field"
            >
              <option selected disabled>
                Choose an Option
              </option>
              <option value="SOLE PROPRIETORSHIP">Sole Proprietorship</option>
              <option value="ONE MAN COMPANY">One Man Company</option>
              <option value="PRIVATE LIMITED">Private Limited</option>
              <option value="LIMITED LIABILITY PARTNERSHIP">
                Limited Liability Partnership
              </option>
            </select>
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-2">
            <label className="cs_input_label cs_heading_color">MSME Registered? (Optional)</label>
            <div className="cs_radio_group">
              <div>
                <input
                  type="checkbox"
                  name="isMsme"
                  onChange={(a) => handleChange("isMsme", a.target.checked)}
                />
                <label className="cs_radio_label" style={{
                  marginLeft: "10px",
                  marginTop: "10px"
                }}>
                  Yes
                </label>
              </div>
            </div>
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-3">
            {formData.isMsme &&
              <>
                <label className="cs_input_label cs_heading_color">
                  MSME/Udyam Registration Type
                </label>
                <select
                  onChange={(a) => handleChange("msmeType", a.target.value)}
                  className="cs_form_field"
                >
                  <option selected disabled>
                    Choose an Option
                  </option>
                  <option value="micro">MICRO</option>
                  <option value="small">SMALL</option>
                  <option value="medium">MEDIUM</option>
                </select>
                <div className="cs_height_42 cs_height_xl_25" />
              </>
            }
          </div>
          <div className="col-lg-3">
            {formData.isMsme &&
              <>
                <label className="cs_input_label cs_heading_color">
                  MSME/Udyam Registration Number
                </label>
                <input
                  className="cs_form_field"
                  placeholder="Enter Registration Number"
                  onChange={(a) => handleChange("msmeNumber", a.target.value)}
                />
                <div className="cs_height_42 cs_height_xl_25" />
              </>
            }
          </div>
          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">District</label>
            <select
              onChange={(a) => _getPlaces("district", a.target.value)}
              className="cs_form_field"
            >
              <option selected disabled>
                Choose an Option
              </option>
              {districts?.map((district, index) => (
                <option value={district?.id} key={index}>
                  {district?.name}
                </option>
              ))}
            </select>
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">Type</label>
            <select
              onChange={(a) => _getPlaces("type", a.target.value)}
              className="cs_form_field"
            >
              <option selected disabled>
                Choose an Option
              </option>
              <option value="PANCHAYAT">Grama Panchayat</option>
              <option value="CORPORATION">Municipal Corporation</option>
              <option value="MUNICIPALITY">Municipality</option>
            </select>
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
          <div className="col-lg-4">
            <label className="cs_input_label cs_heading_color">Place</label>
            <select
              onChange={(a) => handleChange("place", a.target.value)}
              className="cs_form_field"
            >
              <option selected disabled>
                Choose an Option
              </option>
              {places?.map((place, index) => (
                <option value={place?.id} key={index}>
                  {place?.name}
                </option>
              ))}
            </select>
            <div className="cs_height_42 cs_height_xl_25" />
          </div>
        </div>
        {
          <div>
            <div
              style={{
                maxHeight: "81px",
                overflowY: "scroll",
                border: "1px solid rgb(249 245 247)",
                padding: "10px",
                marginTop: "20px",
              }}
            >
              <div>Independent Sales Representative Agreement</div>

              <p>
                This Independent Sales Representative Agreement ("Agreement") is
                made and entered into as of {today}, by and between GetAFixMD
                (hereinafter referred to as “the Company"), and {formData.name ? formData.name : '-----'} , (hereinafter
                referred to as “the Representative”). 1. The Company hereby
                appoints the Representative as an independent sales
                representative to promote and sell its Services within the
                designated territory. 2. The Representative accepts the
                appointment and agrees to use best efforts to promote and sell
                the Company’s Services. 3. The Representative’s designated
                territory is, (hereinafter “the Territory”). The Company
                reserves the right to change the Territory. 4. This Agreement
                shall commence on and continue until terminated in accordance
                with the provisions of this Agreement. 5. The Representative
                shall use best efforts to promote and sell the Company’s
                Services. The Representative shall comply with all applicable
                laws, regulations, and the Company policies that are
                periodically updated or revised. The Representative shall
                provide regular sales reports and other relevant information as
                required by the Company. The Representative shall conduct
                business in a professional manner that reflects positively on
                the Company. 6. The Representative shall use only pre-approved
                sales and marketing content, collaterals and materials or any
                other information to existing or potential customers from the
                Company. The Company will not be responsible for any unwarranted
                usage of any forms of content to sell the Services to the
                customer. In such circumstances the sole responsibility will be
                that of the Representative only. 7. The Representative shall be
                paid a commission on sales as per the commission structure of
                GetAFixMD. The Representative understands that the same will be
                prone to change which will be duly informed to the
                Representative. The Representative shall bear all expenses
                incurred in the performance of their duties unless otherwise
                agreed in writing. 8. The Representative shall ensure that all
                the payments made to GetAFixMD by the potential
                Subscribers/customers are done through the provided payment
                modes and in no circumstances GetAFixMD shall be responsible for
                the payments received or handled by the Representative in any
                other manner. 9. The Representative is an independent contractor
                and not an employee/agent of the Company. This Agreement does
                not create an employer-employee relationship/ partnership/ joint
                venture/ agency relationship. The Representative has no
                authority to bind the Company or enter into any agreements on
                its behalf. 10. The Representative shall maintain the
                confidentiality of all proprietary and confidential information
                received from the Company. The Representative shall not disclose
                any confidential information to third parties without the
                Company’s prior written consent. 11. The Representative may use
                the Company’s trademarks, trade names, and logos solely for the
                purpose of promoting and selling the Services. Such use does not
                confer any rights whatsoever on the Representative. 12. The
                Company and/or the Representative may terminate this Agreement
                with one month’s written notice. The Company may terminate this
                Agreement immediately for cause, including but not limited to
                breach of these terms, misconduct, or non-performance. Upon
                termination, the Representative shall cease all promotional
                activities and return any Company property. 13. The
                Representative agrees to indemnify and hold the Company harmless
                from any claims, liabilities, damages, or expenses arising from
                his actions or omissions. 14. This Agreement is deemed to be
                entered into at Bangalore and is governed by the applicable
                jurisdictional laws. Any disputes arising from this Agreement
                shall be resolved through arbitration. 15. The Company reserves
                the right to amend this Agreement at any time with proper notice
                to the Representative. Continued engagement as a Representative
                after any amendments constitutes acceptance of the revised
                terms. 16. This Agreement constitutes the entire agreement
                between the Company and the Representative and supersedes all
                prior agreements and understandings, whether written or oral,
                relating to its subject matter.
              </p>
            </div>
            <div
              style={{
                marginTop: "40px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  style={{ marginRight: "10px" }} // Adds space between the checkbox and label
                />
                <label style={{ color: "#D2538C" }}>
                  I accept the Terms and Conditions
                </label>
              </div>
              <div className="mt-2">
                {loading ? (
                  <button className="cs_btn cs_style_1">
                    <span>Submit</span>
                    <Lottie options={defaultOptions} width={45} />
                  </button>
                ) : (
                  <button className="cs_btn cs_style_1" onClick={handleSubmit}>
                    <span>Submit</span>
                    <i>
                      <img src={"/images/icons/arrow_white.svg"} alt="Icon" />
                      <img src={"/images/icons/arrow_white.svg"} alt="Icon" />
                    </i>
                  </button>
                )}
                {error && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    Kindly fill in all the required fields.
                  </div>
                )}
                {failed && (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {message ? message : "Something went wrong, Please try again!"}
                  </div>
                )}
                {success && (
                  <div style={{ color: "green", fontSize: "12px" }}>
                    Your details updated successfully, We will connect you
                    shortly!
                  </div>
                )}
              </div>
            </div>

            <div style={{ marginTop: "20px" }}>
              {alert && !isChecked && (
                <div style={{ color: "red" }}>
                  Please accept the Terms and Conditions before continue
                </div>
              )}
            </div>
          </div>
        }
      </div>
    </>
  );
}
