import React, { useEffect, useState } from "react";

import { pageTitle } from "../../helpers/PageTitle";
import DepartmentSectionStyle2 from "../Section/DepartmentSection/DepartmentSectionStyle2";
import Spacing from "../Spacing";
import { Modal } from "antd";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { sendOtp, verifyOtp, logoutUser } from "../../redux/user/userSlice";
import Lottie from "react-lottie";
import loading from "../../loading.json";
import { useNavigate } from "react-router-dom";

const departmentData = [
  {
    title: "24/7 Specialist Access",
    subTitle:
      "Unlimited online consultations with a wide range of healthcare professionals.",
    iconUrl: "/images/icons/calendar_white.svg",
  },
  {
    title: "Unlimited In-Person Consults",
    subTitle:
      "Free, unlimited face-to-face doctor visits across various specialties.",
    iconUrl: "/images/icons/calendar_white.svg",
  },
  {
    title: "Dedicated Family Doctor",
    subTitle:
      "Personalized care from a chosen family doctor for tailored healthcare.",
    iconUrl: "/images/icons/calendar_white.svg",
  },
  {
    title: "Exclusive Wellness Programs",
    subTitle:
      "Access to curated health programs, including early disease detection and wellness advice.",
    iconUrl: "/images/icons/calendar_white.svg",
  },
  {
    title: "Extensive Insurance Coverage",
    subTitle:
      "Comprehensive health and accident insurance with added benefits and coverage.",
    iconUrl: "/images/icons/calendar_white.svg",
  },
];

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function Pricing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);

  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [phoneScreen, setPhoneScreen] = useState(true);
  const [phone, setPhone] = useState(null);
  const [phoneError, setPhoneError] = useState(false);
  const [otpScreen, setOtpScreen] = useState(false);
  const [otp, setOtp] = useState("");

  const [name, setName] = useState(null);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);

  const [isChecked, setIsChecked] = useState(false);
  const [hide, setHide] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  pageTitle("Pricing Plan");

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const __sendOtp = () => {
    if (!phone || phone?.length != 10) {
      setPhoneError(true);
    } else {
      setLoading(true);
      dispatch(sendOtp(phone)).then((response) => {
        if (response.payload) {
          setPhoneError(false);
          setPhoneScreen(false);
          setOtpScreen(true);
          setLoading(false);
        }
      });
    }
  };

  const __verifyOtp = async () => {
    if (!otp || otp.length !== 4) {
      setError(true);
      setMessage('Enter a valid OTP');
      setTimeout(() => {
        setError(false);
        setMessage(null);
      }, 3000);
      return;
    }

    if (user?.message === "NEWUSER") {
      if (!name) {
        setError(true);
        setMessage('Enter your name');
        setTimeout(() => {
          setError(false);
          setMessage(null);
        }, 3000);
        return;
      }

      if (!isChecked) {
        setError(true);
        setMessage('Please accept the terms and conditions');
        setTimeout(() => {
          setError(false);
          setMessage(null);
        }, 3000);
        return;
      }

      setLoading(true);

      // Dispatch OTP verification and check response
      dispatch(
        verifyOtp({
          phone: phone,
          otp: otp,
          name: name,
        })
      ).then((response) => {
        setLoading(false);
        if (response?.payload?.success) { // Assuming response has payload.success indicating success
          setIsVerified(true);

          navigate("/onboarding");
        } else {
          setError(true);
          setMessage('Enter a valid OTP');
          setTimeout(() => {
            setError(false);
            setMessage(null);
          }, 3000);
          return;
        }
      });
      // }
    } else {
      setLoading(true);
      setError(false);

      await dispatch(
        verifyOtp({
          phone: phone,
          otp: otp,
          name: name,
        })
      ).then((response) => {
        setLoading(false);
        if (response?.payload?.success) {
          setIsVerified(true);

          navigate("/onboarding");
        } else {
          setError(true);
          setMessage('Enter a valid OTP');
          setTimeout(() => {
            setError(false);
            setMessage(null);
          }, 3000);
          return;
        }
      });
    }
  };

  useEffect(() => {
    isVerified == 'true' &&
      navigate('/onboarding')
  }, [isVerified]);

  const handleGetStarted = () => {
    if (user?.success && user?.user?.token) {
      dispatch(logoutUser());
    }
    setShowPopup(true);
  };

  useEffect(() => {
    if (user?.message != "NEWUSER") {
      setHide(true);
    } else {
      setHide(false);
    }
  }, [user]);

  return (
    <>
      {/* <Hero
        title="Your Partner in Health and Wellness"
        subTitle="We are committed to providing you with the best medical and healthcare services to help you live healthier and happier."
        bgUrl="/images/home_1/bg2.jpg"
        imgUrl="/images/home_1/38965.png"
        btnText="Get Started"
        setShowPopup={handleGetStarted}
      /> */}

      <Spacing md="200" lg="200" xl="200" />
      <DepartmentSectionStyle2
        sectionTitle="Your Benefits"
        data={departmentData}
        handleGetStarted={handleGetStarted}
      />

      <Spacing md="100" lg="100" xl="100" />
      <div className="container">
        <div className="cs_banner_text row">
          <div className="col-xl-6 col-md-12">
            <h2 className="cs_banner_title cs_fs_72">
              Find the Right Plan for You
            </h2>
            <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_heading_color">
              Explore Our Membership Options and Start Your Journey to Better
              Health
            </p>
          </div>
          <div className="col-xl-6 col-md-12 text-center">
            <Spacing md="50" lg="50" xl="100" />
            <div className={`cs_btn cs_style_1`} onClick={handleGetStarted}>
              <span>Get Started</span>
              <i>
                <img src="images/icons/arrow_white.svg" alt="Icon" />
                <img src="images/icons/arrow_white.svg" alt="Icon" />
              </i>
            </div>
          </div>
        </div>
      </div>

      {/* <Spacing md="150" lg="150" xl="150" />
      <WorkingProcess
        sectionTitle="How it Works"
        sectionTitleUp=""
        sectionTitleDown=""
        sectionSubTitle=""
        data={workingProcessData}
      /> */}

      {/* <Spacing md="150" lg="150" xl="150" />
      <FaqSection
        data={faqData}
        sectionTitle="Usually Asked"
        sectionTitleUp="What People"
      /> */}

      <Modal
        visible={showPopup}
        onOk={() => setShowPopup(false)}
        onCancel={() => setShowPopup(false)}
        footer={null}
      >
        <div className="cs_newsletter cs_style_1">
          <h2 className="cs_newsletter_title" style={{ fontSize: "25px" }}>
            Get Started
          </h2>
          {phoneScreen && (
            <>
              {phoneError ? (
                <div
                  className="cs_newsletter_subTitle"
                  style={{ color: "red" }}
                >
                  Please enter a valid phone number
                </div>
              ) : (
                <div className="cs_newsletter_subTitle">
                  Please enter your mobile number to get started
                </div>
              )}
              <div className="cs_newsletter_form" style={{ marginTop: "45px" }}>
                <div className="d-flex align-items-center">
                  <div
                    className={`${!isFocused ? "fix-input" : "fix-input-focus"
                      }`}
                  >
                    +91
                  </div>
                  <input
                    type="tel"
                    className="cs_form_field"
                    style={{
                      borderRadius: "0 2.4em 2.4em 0",
                      borderLeft: "none",
                      paddingLeft: "0px",
                    }}
                    placeholder="9876543210"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    value={phone}
                    onChange={(a) => {
                      setPhone(a.target.value);
                      setPhoneError(false);
                    }}
                  />
                </div>
                {loading ? (
                  <button className="cs_btn cs_style_1">
                    <span>Start</span>
                    <Lottie options={defaultOptions} width={45} />
                  </button>
                ) : (
                  <button className="cs_btn cs_style_1" onClick={__sendOtp}>
                    <span>Start</span>
                    <i>
                      <img src={"/images/icons/arrow_white.svg"} alt="Icon" />
                      <img src={"/images/icons/arrow_white.svg"} alt="Icon" />
                    </i>
                  </button>
                )}
              </div>
            </>
          )}
          {otpScreen && (
            <>
              <div className="cs_newsletter_subTitle">
                Please enter the OTP sent to &nbsp;
                <span style={{ fontWeight: "bold" }}>{phone}</span>
                &nbsp;&nbsp;
                <span
                  onClick={() => {
                    setOtpScreen(false);
                    setPhoneScreen(true);
                  }}
                  style={{ fontWeight: "bold", color: "#D2538C" }}
                >
                  Change number
                </span>
              </div>
              {error && (
                <div
                  className="cs_newsletter_subTitle"
                  style={{ color: "red" }}
                >
                  {message}
                </div>
              )}
              <div className="cs_newsletter_form" style={{ marginTop: "30px" }}>
                <div style={{ paddingLeft: "25px", paddingRight: "25px" }}>
                  <label className="cs_input_label cs_heading_color">OTP</label>
                  <div className="d-flex justify-content-center">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      shouldAutoFocus={true}
                      inputStyle={{
                        border: "1px solid rgba(210, 83, 140, 0.5)",
                        borderRadius: "15px",
                        padding: "16px 20px",
                        marginRight: "20px",
                        color: "#D2538C",
                        width: "4em",
                        marginBottom: "25px",
                      }}
                      renderInput={(props) => <input {...props} />}
                    />
                  </div>

                  {user?.message == "NEWUSER" && (
                    <React.Fragment>
                      <label className="cs_input_label cs_heading_color">
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="cs_form_field"
                        placeholder="Enter your Full Name"
                        value={name}
                        onChange={(a) => setName(a.target.value)}
                      />
                    </React.Fragment>
                  )}
                </div>
                <div
                  className="d-flex align-items-start justify-content-between"
                  style={{ marginTop: "50px" }}
                >
                  <div className="cs_newsletter_subTitle">
                    <span style={{ fontWeight: "bold" }}>Didn't get OTP?</span>
                    &nbsp;&nbsp;
                    <span
                      onClick={() => {
                        // setOtpScreen(false)
                        // setPhoneScreen(true)
                      }}
                      style={{ fontWeight: "bold", color: "#D2538C" }}
                    >
                      Resend
                    </span>
                  </div>

                  {loading ? (
                    <button
                      className="cs_btn cs_style_1"
                      style={{ position: "unset", marginRight: "20px" }}
                    >
                      <span>Done</span>
                      <Lottie options={defaultOptions} width={45} />
                    </button>
                  ) : (
                    <button
                      className="cs_btn cs_style_1"
                      onClick={__verifyOtp}
                      style={{ position: "unset", marginRight: "20px" }}
                    >
                      <span>Done</span>
                      <i>
                        <img src={"/images/icons/arrow_white.svg"} alt="Icon" />
                        <img src={"/images/icons/arrow_white.svg"} alt="Icon" />
                      </i>
                    </button>
                  )}
                </div>

                {user?.message == "NEWUSER" && (
                  <div>
                    <div
                      style={{
                        maxHeight: "81px",
                        overflowY: "scroll",
                        border: "1px solid rgb(249 245 247)",
                        padding: "10px",
                        marginTop: "20px",
                      }}
                    >
                      <div>
                        Terms & Conditions, Privacy Policy and Refund Policy
                      </div>
                      <p style={{ textAlign: "left", color: "#7D7F7D" }}>
                        1. Introduction Welcome to the Services of GetAFixMD.
                        These Terms and Conditions govern the use of Services of
                        GetAFixMD. The details of the Services of GetAFixMD are
                        available here{" "}
                        <a
                          href="https://pricing.GetAFixMD.com/"
                          target="_blank"
                        >
                          https://pricing.GetAFixMD.com/
                        </a>
                        . Please go through the same before agreeing to these
                        Terms and Conditions. Any doubts or clarifications about
                        the Services of GetAFixMD or these Terms and Conditions
                        by referring to
                        <a
                          href="https://GetAFixMD.com/frequently-asked-questions-2"
                          target="_blank"
                        >
                          https://GetAFixMD.com/frequently-asked-questions-2
                        </a>
                        . By subscribing to Services of GetAFixMD, the
                        Subscriber agrees to these Terms and Conditions and also
                        to the terms and conditions as prescribed by its Service
                        Partners. If you do not agree, please do not subscribe
                        to the Services of GetAFixMD.
                        <br />
                        <br />
                        2. Definitions
                        <br />
                        2.1 "Subscriber" refers to any individual who belongs to
                        the age range of 18 to 65 years and includes Family
                        Member(s) who is enrolled by him, that subscribes to the
                        three specific services namely Health Care Service,
                        Health Insurance (Complementary), Wellness Service,
                        provided by GetAFixMD either directly or through its
                        Service Partners. The details of which are available
                        here
                        <a
                          href="https://GetAFixMD.com/frequently-asked-questions-2"
                          target="_blank"
                        >
                          https://GetAFixMD.com/frequently-asked-questions-2
                        </a>
                        Currently we have only two biological options of male
                        and female during the onboarding process. A minor cannot
                        be offered insurance, however, a minor can be covered
                        when at least the self (parent, legal guardian) is
                        covered in the policy.
                        <br />
                        <br />
                        2.2 “Family Member” means Father, Mother, Sibling,
                        within the age range of 91 days to 65 years of the
                        subscriber. The maximum members in the family is four,
                        2Adults and 2Children.
                        <br />
                        <br />
                        2.3 "Services" refer to the services offered by
                        GetAFixMD namely Health Care Service, Health Insurance
                        (Complementary), Wellness Service to the Subscribers
                        directly or through its Service Partners including but
                        not limited to consultations, treatments, wellness
                        programs and insurance services more fully detailed in
                        <a href="https://pricing.GetAFixMD.com" target="_blank">
                          https://pricing.GetAFixMD.com
                        </a>
                        .
                        <br />
                        <br />
                        2.4 “Form of Subscription” refers to the online
                        subscription form available on the website by which the
                        subscription to Services is provided.
                        <br />
                        <br />
                        2.5 “Service Partners” means the entities with which
                        GetAFixMD has partnered for providing of Services.
                        <br />
                        <br />
                        3. Subscription and Registration
                        <br />
                        5.1 The Subscriber understands that by availing the
                        subscription to the three specific Services that is
                        Health Care Service, Health Insurance (Complementary)
                        and Wellness Service, is hereby consenting to avail the
                        same.
                        <br />
                        <br />
                        5.2 The Subscriber has read and understood and hereby
                        accepts the scope and terms of Health Care Services.
                        <br />
                        <br />
                        5.3 The Subscriber has read, understood, and hereby
                        accepts the scope and terms, disclaimer, and privacy
                        policy with respect to the Health Insurance Service
                        (Complementary).
                        <br />
                        <br />
                        5.4 The Subscriber has read, understood, and hereby
                        accepts the scope and terms of Wellness Services.
                        <br />
                        <br />
                        5.5 The Subscriber by availing subscription consents to
                        receiving communications, that may include promotional
                        content, from GetAFixMD through SMS, Email, Phone.
                        <br />
                        <br />
                        6. Fees and Payment
                        <br />
                        6.1 GetAFixMD onboard customers to a yearly subscription
                        plan of Services, at the specified fees. Customers can
                        choose suitable subscription packages as per their
                        choice and eligibility. GetAFixMD reserves the right to
                        change the subscription plan and the fee as and when it
                        is needed. However, once a subscription is availed and
                        fees can be paid in accordance with the selected
                        subscription plan.
                        <br />
                        <br />
                        6.2 Payment can be made via online digital payment
                        methods through the payment gateway in the website of
                        GetAFixMD. No other means of payment including cash,
                        cheque, demand draft etc. are accepted by GetAFixMD.
                        <br />
                        <br />
                        6.3 On completion of the payment only the subscription
                        process will get completed and the Services will be
                        provided. Failure in realization of payment of
                        subscription fees may result in suspension or
                        termination of the Services.
                        <br />
                        <br />
                        7. Cancellation and Refunds
                        <br />
                        7.1 Subscribers may cancel their subscription within
                        seven days from the date of Subscription, provided no
                        Services have been availed.
                        <br />
                        <br />
                        7.2 The Subscriber is eligible for a full refund of
                        payment if subscription is canceled as per Clause 7.1.
                        The refund of payment is also done if the subscription
                        process is not completed owing to some technical issues
                        or for some other reason. Refunds of payment will be
                        processed and done within a period of 14 days from the
                        date of cancellation as per Clause 7.1. In the case of
                        non-completion of subscription process, the refund will
                        be done within 14 days from the date of the information
                        of non-completion of subscription process.
                        <br />
                        <br />
                        8. Confidentiality and Privacy
                        <br />
                        8.1 Data management and policy which explains how we
                        handle Subscriber information, is available here
                        <a
                          href="https://GetAFixMD.com/frequently-asked-questions-2"
                          target="_blank"
                        >
                          https://GetAFixMD.com/frequently-asked-questions-2
                        </a>
                        .
                        <br />
                        <br />
                        8.2 All subscriber information is confidential and will
                        be used solely for providing Services as per these Terms
                        and Conditions.
                        <br />
                        <br />
                        9. Limitation of Liability
                        <br />
                        9.1 The Services are provided on an "as is" and "as
                        available" basis. GetAFixMD does not guarantee that the
                        Services will be uninterrupted or error-free.
                        <br />
                        <br />
                        9.2 GetAFixMD is not liable for any indirect,
                        incidental, or consequential damages arising from the
                        use of Services.
                        <br />
                        <br />
                        9.3 In any event, the maximum liability of GetAFixMD to
                        the Subscriber shall not exceed an amount of Rs.
                        10,000/-.
                        <br />
                        <br />
                        10. Subscriber Responsibilities
                        <br />
                        10.1 Subscribers must provide accurate and complete
                        information during the registration process and keep it
                        updated.
                        <br />
                        <br />
                        10.2 Subscribers must comply with all applicable laws
                        and regulations in connection with their use of the
                        Services.
                        <br />
                        <br />
                        10.3 Subscribers must not engage in any conduct that
                        could damage or impair the Services or interfere with
                        any other party’s use of the Services.
                        <br />
                        <br />
                        11. Force Majeure
                        <br />
                        11.1"Force Majeure Event" means any event or
                        circumstance beyond the reasonable control of anyone,
                        including but not limited to acts of God, natural
                        disasters, war, terrorism, civil commotion, strikes,
                        labor disputes, governmental actions, epidemics,
                        pandemics, technological glitches, connectivity issues,
                        virus and likewise attacks, denial of service attacks or
                        any other event that could not have been anticipated or
                        prevented through reasonable measures.
                        <br />
                        <br />
                        11.2 If anyone is prevented, hindered, or delayed in
                        performing any of its obligations due to a Force Majeure
                        Event, that party shall not be deemed to be in breach of
                        this or otherwise liable for any such failure or delay
                        in the performance of its obligations.
                        <br />
                        <br />
                        11.3 There shall be a prompt notification of the
                        occurrence of the Force Majeure Event, providing
                        reasonable details about the nature and extent of the
                        event, its anticipated duration, and the steps being
                        taken to mitigate its impact.
                        <br />
                        <br />
                        12. Miscellaneous
                        <br />
                        12.1 GetAFixMD reserves the right to terminate or
                        suspend a subscription without notice for breach of
                        Terms and Conditions or for any other reason deemed
                        appropriate.
                        <br />
                        <br />
                        12.2 GetAFixMD may amend these Terms and Conditions at
                        any time which shall be communicated for the
                        confirmation by the Subscribers for continuation for
                        availing of Services.
                        <br />
                        <br />
                        12.3 These agreements are agreed to be entered into at
                        Bangalore and are governed by the applicable
                        jurisdictional laws.
                      </p>
                      <div>
                        Terms and Conditions with respect to Consent for Data
                        Collection, Transfer and Processing and Use
                      </div>
                      <p style={{ textAlign: "left", color: "#7D7F7D" }}>
                        . Introduction
                        <br />
                        These Terms and Conditions are with respect to Personal
                        Data, Data Transfer, Data Processor and use in availing
                        of Services. By consenting to the disclosure of your
                        data, you agree to these Terms and Conditions.
                        <br />

                        2.
                        Definitions**
                        <br />

                        2.1 "Personal Data" means any information
                        that relates to a Subscriber, which either directly or
                        indirectly or in combination with other information,
                        available with GetAFixMD is capable of identifying such
                        Subscriber.
                        <br />

                        2.2 "Data Transfer" refers to the transfer
                        of Personal Data to a third party.
                        <br />
                        2.3 "Controller"
                        refers to GetAFixMD, which determines the purposes and
                        means of processing Personal Data.
                        <br />
                        2.4 "Processor"
                        refers to any third party that processes Personal Data
                        on behalf of the Controller. 3. Consent
                        <br />
                        3.1 By agreeing
                        to these Terms and Conditions you provide explicit
                        consent for GetAFixMD to use, process and transfer your
                        Personal Data as described herein.
                        <br />
                        3.2 You understand
                        that you have the right to withdraw your consent at any
                        time by contacting GetAFixMD and you understand that
                        such withdrawal will also result in discontinuation of
                        your subscription. You also understand that withdrawal
                        of consent does not affect the lawfulness of processing
                        of Personal Data based on consent before its withdrawal.
                        <br />

                        3.3 You hereby consent to transfer your Personal Data to
                        for You understand that the transfer of your Personal
                        Data is necessary for the performance of the
                        Subscription.
                        <br />
                        3.4 You understand that you have the right
                        to request access to your Personal Data and obtain
                        information about its processing and you have the right
                        to request the correction of inaccurate or incomplete
                        Personal Data. You understand that you have the right to
                        request the deletion of your Personal Data under certain
                        conditions and you have the right to request the
                        restriction of processing your Personal Data under
                        certain conditions. You have the right to receive your
                        Personal Data in a structured, commonly used, and
                        machine-readable format.
                        <br />
                        4. Data Security 4.1 GetAFixMD
                        will implement appropriate technical and organisational
                        measures to ensure the security of your Personal Data
                        during transfer and processing and will ensure that the
                        same is in tune with all the laws and obligations with
                        respect to the same.
                        <br />
                        4.2 In the event of a data breach,
                        GetAFixMD will notify you and the relevant authority.
                        <br />

                        4.3 Any Processors involved in the data transfer are
                        bound by contractual obligations to process Personal
                        Data in compliance
                        <br />
                        4.4 Your personal data will be
                        retained for as long as necessary to fulfil the purposes
                        for which it was collected or as required by law and
                        upon expiration of the retention period, your personal
                        data will be securely deleted or anonymized.
                      </p>
                    </div>
                    <div
                      style={{
                        marginTop: "40px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          style={{ marginRight: "10px" }} // Adds space between the checkbox and label
                        />
                        <label style={{ color: "#D2538C" }}>
                          I accept the Terms and Conditions
                        </label>
                      </div>
                      {/* <button
                          onClick={handleContinueClick}
                          className="cs_btn cs_style_1"
                          style={{
                            position: "unset",
                            marginRight: "20px",
                            color: "#fff",
                          }}
                        // style={{ padding: "10px 20px", backgroundColor: "#D2538C", color: "#fff", border: "none", borderRadius: "5px" }}
                        >
                          <span>Continue</span>
                        </button> */}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}
